import React from 'react';

import { Page } from 'components/_shared/Page';
import { MarketplaceProductTemplateDefault } from '../Template';

const TEMPLATES = {
  default: MarketplaceProductTemplateDefault
};

export const MarketplaceProductPage = ({ pageContext: product, ...props }) => {
  const Template = TEMPLATES[product.template] || TEMPLATES.default;

  return (
    <Page className='marketplace-product' meta={product.seo} withContainer>
      <Template {...props} product={product} />
    </Page>
  );
};
