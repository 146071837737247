import React, { useState } from 'react';
import cn from 'classnames';

import { IconChevronDown } from 'assets';

import { Button } from '../Button';

import './Accordion.styles.scss';

function normalizeItems(items = []) {
  return items.reduce((state, item, i) => {
    return {
      ...state,
      [i]: {
        hasContent: !!item.content,
        isCollapsed: true,
        ...item,
        i
      }
    };
  }, {});
}

export const Accordion = ({ className, items = [] }) => {
  const [_items, setItems] = useState(() => normalizeItems(items));

  const _className = cn('accordion', className);

  const handleItemClick = item => {
    setItems(state => ({
      ...state,
      [item.i]: { ...item, isCollapsed: !item.isCollapsed }
    }));
  };

  const renderItems = Object.values(_items).map(item => {
    const { hasContent, isCollapsed } = item;
    const canRenderHeader = item.title || item.content;
    const className = cn('accordion-item', { hasContent, isCollapsed });

    if (!item.title && !hasContent) return null;

    return (
      <article key={item.i} className={className}>
        {canRenderHeader && (
          <header className='accordion-item-header'>
            {item.title && (
              <h4 className='accordion-item-title'>{item.title}</h4>
            )}

            {hasContent && (
              <>
                <span className='accordion-item-toggle-icon'>
                  <IconChevronDown />
                </span>

                <Button
                  className='accordion-item-toggle'
                  onClick={() => handleItemClick(item)}
                />
              </>
            )}
          </header>
        )}

        {hasContent && (
          <div className='accordion-item-content'>{item.content}</div>
        )}
      </article>
    );
  });

  return <div className={_className}>{renderItems}</div>;
};
