import React, { useMemo } from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import cn from 'classnames';
import flatten from 'lodash-es/flatten';

import { ROUTES } from '_constants';

import { Tags } from 'components/_shared/Tags';
import { Tabs } from 'components/_shared/Tabs';
import { Link } from 'components/_shared/Link';
import { Action } from 'components/_shared/Action';
import { Section } from 'components/_shared/Section';
import { Markdown } from 'components/_shared/Markdown';
import { Accordion } from 'components/_shared/Accordion';

import { useStrapiActions, useStrapiCommonContent } from 'hooks';

import './MarketplaceProductTemplateDefault.styles.scss';

export const MarketplaceProductTemplateDefault = ({ className, product }) => {
  const commonContent = useStrapiCommonContent();
  const { getActionById } = useStrapiActions();

  const _className = cn('marketplace-product-template', 'default', className);

  const action = useMemo(() => {
    if (!product.call_to_actions) return null;
    return getActionById(product.call_to_actions.actions.id);
  }, [product.call_to_actions, getActionById]);

  const buildTab = tab => {
    const isPrimaryTabVariant = tab.variant === 'primary';

    const getSectionProps = (() => {
      let sectionIndex = 0;

      return () => {
        const isFirstSection = sectionIndex++ === 0;
        const sectionVariant = isPrimaryTabVariant
          ? isFirstSection
            ? 'primary'
            : 'aside'
          : 'primary';

        return { variant: sectionVariant };
      };
    })();

    const fieldComponents = {
      sections: sections => {
        if (!sections?.length) return null;

        return sections.map(section => (
          <Section
            {...getSectionProps()}
            key={section.id}
            heading={section.title}
            supheading={section.supheading?.data?.supheading}
            subheading={section.subheading?.data?.subheading}
            icon={section.icon?.[0]?.url}
            className='content'
          >
            <Markdown markdown={section.content?.data?.content} />
          </Section>
        ));
      },

      faq: faq => {
        if (!faq?.length) return null;

        return (
          <Section {...getSectionProps()} key='faq' heading={commonContent.faq}>
            <Accordion
              items={faq.map(({ faq }) => ({
                title: faq.title,
                content: <Markdown markdown={faq.content?.data} />
              }))}
            />
          </Section>
        );
      },

      quick_links: links => {
        if (!links?.length) return null;

        return (
          <Section {...getSectionProps()} key='links' className='quick-links'>
            <h2 className='quick-links-heading'>{commonContent.quick_links}</h2>
            <ul className='quick-links-list'>
              {links.map(({ id, text, link }) => (
                <li key={id} className='quick-links-item'>
                  <Link href={link}>{`${text} ${commonContent.arrow}`}</Link>
                </li>
              ))}
            </ul>
          </Section>
        );
      }
    };

    let components = flatten(
      Object.entries(tab).reduce((components, [field, value]) => {
        return field in fieldComponents
          ? [...components, fieldComponents[field]?.(value)]
          : components;
      }, [])
    );

    if (isPrimaryTabVariant) {
      const [first, ...rest] = components;

      components = [
        first,
        <div key='inner' className='inner'>
          {rest}
        </div>
      ];
    }

    return { className: tab.variant, render: <>{components}</> };
  };

  const tabs = product.tabs?.reduce((tabs, tab) => {
    return { ...tabs, [tab.title]: buildTab(tab) };
  }, {});

  const _tags = product.tags?.map(tag => ({
    ...tag,
    link: ROUTES.MARKETPLACE
  }));

  return (
    <div className={_className}>
      <header className='product-header'>
        {/* TODO: Return this link after the Marketplace page implementation */}
        {/* <Link className='back-link' href={ROUTES.MARKETPLACE}>
          {commonContent.back_to_marketplace}
        </Link> */}

        {!!product.image?.length && (
          <div className='product-image'>
            <GatsbyImage
              image={getImage(product.image[0].localFile)}
              alt={product.title}
            />
          </div>
        )}

        {(product.title || product.description?.data?.description) && (
          <div className='inner'>
            {product.title && (
              <h1 className='product-title'>{product.title}</h1>
            )}
            {product.description?.data?.description && (
              <p className='product-description'>
                {product.description.data.description}
              </p>
            )}
          </div>
        )}

        {action && (
          <div className='product-actions'>
            <Action
              {...action}
              className='product-action call-to-action'
              variant='primary'
            />
          </div>
        )}

        {!!_tags?.length && <Tags className='product-tags' tags={_tags} />}
      </header>

      <Tabs tabs={tabs} />
    </div>
  );
};
