import React from 'react';
import { Link } from 'gatsby';
import cn from 'classnames';

import './Tags.styles.scss';

export const Tags = ({ className, tags = [], ...props }) => {
  const _className = cn('tags', className);

  if (!tags?.length) return null;

  return (
    <div {...props} className={_className}>
      {tags.map(tag => {
        return tag.link ? (
          <Link key={tag.title} to={tag.link} className='tag'>
            {tag.title}
          </Link>
        ) : (
          <span className='tag'>{tag.title}</span>
        );
      })}
    </div>
  );
};
